@import '../../assets/styles/sizes';

$bg-font-size-xl: 72px;
$bg-font-size-m: 54px;
$bg-font-size-s: 46px;
$title-font-size: 25px;

.host {
  position: relative;
  margin-bottom: 24px;
  font-family: var(--secondary-font);
}

.title {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  color: var(--black-500);
  font-size: $title-font-size;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: none;
  letter-spacing: 6.6px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.titleBackground {
  white-space: nowrap;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: 6px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--white);
  text-shadow: -1px -1px 0 var(--gray-300), 1px -1px 0 var(--gray-300), -1px 1px 0 var(--gray-300),
    1px 1px 0 var(--gray-300);
  user-select: none;
  position: relative;
  background-repeat: repeat-y;
  background-position: top center;

  @include media-xl {
    font-size: $bg-font-size-m;
  }
  @include media-sm {
    text-shadow: none;
    color: transparent;
  }
}

.subtitle {
  font-family: var(--primary-font);
  width: 50%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--black-500);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 8px auto;
  @include media-md {
    width: 100%;
    margin: 0 auto;
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.nowrap {
  @include media-sm {
    white-space: nowrap;
  }
}

.normal {
  @include media-sm {
    top: 60%;
    white-space: normal;
    line-height: 34px;
  }
}
