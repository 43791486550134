@import '../../../../assets/styles/sizes';

.container {
  max-width: 520px;
  margin: 0 auto;
  position: relative;
  padding: 8px;

  @include media-sm {
    padding: 0;
  }
}

.loadingContainer {
  min-height: 480px;
  position: relative;
}

.label {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.31px;
  color: var(--gray-500);
}

.error {
  color: red;
  display: block;
  padding-bottom: 20px;
}

.title {
  margin-bottom: 0;
}

.messageParagraph {
  display: block;
  margin-bottom: 8px;

  &:last-child {
    margin: 0;
  }
}

.paymentList {
  max-width: 520px;
  margin: 0 auto;
  padding-top: 32px;
  display: flex;
  justify-content: center;

  @include media-sm {
    padding-top: 16px;
  }
}

.icon {
  width: auto;
  height: 32px;
  margin: auto 10px;

  @include media-sm {
    height: 24px;
  }
}
