@import '../../assets/styles/sizes';

.container {
  margin-bottom: 24px;
  align-items: center;
}

.controlTitle {
  @include media-sm {
    padding-right: 0;
    margin-bottom: 8px;
  }
}

.radioGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  @include media-sm {
    gap: 8px;
  }
}

.radioBtn {
  --left-margin: 32px;
  --icon-size: 15px;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.outlined {
    label {
      --vertical-padding: 8px;
      --horizontal-padding: 16px;

      box-sizing: border-box;
      border: 1px #b8b8b8 solid;
      padding: var(--vertical-padding) var(--horizontal-padding) var(--vertical-padding)
        calc(var(--left-margin) + 8px);
      border-radius: 100px;

      &:after {
        left: var(--horizontal-padding);
      }
    }

    input[type='radio']:checked ~ label {
      border: 1px solid var(--black-500);
      background-color: var(--gray-100);
    }
  }

  input[type='radio']:checked ~ label::after {
    border-color: var(--black-500);
    background-image: url(./images/radio.svg);
    background-position: center;
    background-size: contain;
    border: 0;
  }

  input[type='radio']:focus-visible + label {
    outline: 2px solid var(--blue-200);
  }

  label {
    color: var(--black-500);
    line-height: 17px;
    font-size: 14px;
    font-weight: 500;
    padding-left: var(--left-margin);
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: block;

    &::after {
      content: '';
      position: absolute;
      width: var(--icon-size);
      height: var(--icon-size);
      border-radius: 50%;
      z-index: 999;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      padding: 1px;
      border: 1px solid var(--black-500);
    }
  }
}
