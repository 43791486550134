@import '../../../../assets/styles/sizes.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  @include media-sm {
    justify-content: flex-start;
  }
}

.radioBtn {
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &.outlined {
    label {
      box-sizing: border-box;
      border: 1px #b8b8b8 solid;
      padding: 6px 24px;
      border-radius: 100px;

      &:after {
        left: var(--horizontal-padding);
      }
    }

    input[type='radio']:checked ~ label {
      border: 1px solid var(--black-500);
      background-color: var(--gray-100);
    }
  }

  input[type='radio']:focus-visible + label {
    outline: 2px solid var(--blue-200);
  }

  label {
    color: var(--black-500);
    line-height: 17px;
    font-size: 14px;
    font-weight: 500;
    padding-left: var(--left-margin);
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: block;
  }
}
