@import '../../../../assets/styles/sizes';

.container {
  width: 100%;
  max-width: 1200px;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  gap: 24px 16px;

  @include media-sm {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
  }
}

.item {
  width: 182px;

  @include media-sm {
    width: 100%;
  }
}

.selectWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  & label {
    padding-left: 0 !important;
    font-family: var(--secondary-font);
    font-weight: 300;
    font-size: 26px;
    line-height: 28px;
    color: var(--black-500);
    border-bottom: 1px solid var(--black-500);
    padding-bottom: 4px;
    &::before,
    &::after {
      display: none;
    }
  }
}
