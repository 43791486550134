.root {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 16px;
  display: grid;
  gap: 16px;
  grid-template-areas:
    'leftIcon content rightActions'
    'leftIcon actions actions';
  grid-template-columns: auto 1fr auto;
}

.leftIconContainer {
  grid-area: leftIcon;
  padding-top: 8px;
}

.contentContainer {
  max-width: 634px;
  grid-area: content;
}

.contentName {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.08em;
  margin-bottom: 8px;
}
.contentAddress {
  line-height: 24px;
}

.contentTitle {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
}

.actionsContainer {
  grid-area: actions;
}

.rightActionsContainer {
  grid-area: rightActions;
}
