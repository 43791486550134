.host {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 16px;
}

.brandText {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::first-letter {
    text-transform: capitalize;
  }
}

.top {
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.3125px;
  color: var(--black-500);
}

.bottom {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.3px;
  color: var(--gray-500);
}

.makeDefault {
  display: inline-block;
  font-size: 10px;
  margin-left: 8px;

  a,
  a:visited {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
}

.popover {
  width: 300px;
  padding: 1em;
  background: var(--white);
  box-shadow: 0 15px 35px 0 rgba(51, 64, 82, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;

  &:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: var(--white) transparent transparent transparent;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  padding: 1em 0 0;
}

.makeDefaultButton {
  cursor: pointer;
  display: inline-block;
  background: transparent;
  text-decoration: none;
  color: inherit;
  border: none;

  &:active {
    background: transparent;
  }
}
