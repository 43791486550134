body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.paginationWrapper {
  position: absolute;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 9;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 0 16px;
  width: calc(50% - 40px);
}

.pagination > .swiper-pagination-bullet {
  opacity: 1;
  background-color: var(--gray-300);
}

.pagination > .swiper-pagination-bullet-active {
  border: 1px solid var(--black-500);
  background-color: var(--white);
}

@media (max-width: 1200px) {
  .pagination {
    width: calc(50% - 20px);
  }
}

@media (max-width: 992px) {
  .pagination {
    width: calc(50% - 12px);
  }
}

@media (max-width: 767px) {
  .paginationWrapper {
    position: static;
    justify-content: center;
    max-width: 100px;
  }

  .pagination {
    width: 100px;
    align-items: center;
  }
}

.rc-slider,
.rc-slider-rail {
  position: relative;
  background-color: var(--black-500);
  height: 10px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 32px;
}

.rc-slider-track {
  display: none;
}

.rc-slider-handle {
  position: absolute;
  top: -10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0px 4px 24px rgb(0 0 0 / 25%);
  padding: 4px;
  cursor: pointer;
  background-image: url(assets/images/logos/logo-mini.svg);
  background-size: cover;
  z-index: 2;
}

.rc-slider::after,
.rc-slider::before {
  content: '';
  position: absolute;
  top: 0;
  border-radius: 5px;
  height: 10px;
  width: 100%;
}

.rc-tooltip-arrow {
  border-bottom-color: var(--black-500) !important;
}

.rc-tooltip-inner {
  background-color: var(--black-500) !important;
  font-family: var(--primary-font);
}

@media (max-width: 767px) {
  .rc-slider::before,
  .rc-slider::after {
    width: 80px;
  }
}
