@import '../../../../assets/styles/sizes';

.wrapper {
  padding: 16px 0;
}

.title {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin: 16px 0;
}

.sliderTop {
  margin-bottom: 16px;
  min-height: 70px;
  display: flex;
  align-items: center;

  @include media-sm {
    min-height: 154px;
  }
}

.info {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--white);
}
