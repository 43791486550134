@import '../../../../../assets/styles/sizes';
.container {
  max-width: 634px;
  margin: 0 auto;
}

.loginText {
  margin-left: 25%;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray-500);
  @include media-sm {
    margin-left: 0;
  }
}

.loginLink {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--gray-500);
  }
}
