.links {
  z-index: 1;
  display: flex;
}

.linksItem {
  margin-right: 32px;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  color: var(--black-500);

  @media (max-width: 767px) {
    margin-right: 16px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:focus-visible {
    outline: none;
    & svg {
      outline: 2px solid var(--blue-200);
    }
  }

  &:hover,
  &.active {
    &::before {
      content: '';
      width: calc(100% + 12px);
      height: 2px;
      top: 0;
      left: 0;
      position: absolute;
      background: var(--black-500);
    }
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  @media (max-width: 767px) {
    display: none;
  }
}

.linkIcon {
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 8px;

  @media (max-width: 767px) {
    margin: 0;
  }
}

.faqIcon {
  background-image: url('../../../../assets/images/icons/topbar.svg');
  background-position: -20px 0;
}

.userIcon {
  background-image: url('../../../../assets/images/icons/topbar.svg');
  background-position: -40px 0;
}
