// Extra large devices
$screen-xl-width: 1200px;
@mixin media-xl {
  @media (max-width: #{$screen-xl-width}) {
    @content;
  }
}

// Large devices
$screen-lg-width: 1024px;
@mixin media-lg {
  @media (max-width: #{$screen-lg-width}) {
    @content;
  }
}

// Medium devices
$screen-md-width: 992px;
@mixin media-md {
  @media (max-width: #{$screen-md-width}) {
    @content;
  }
}

// Small devices
$screen-sm-width: 767px;
@mixin media-sm {
  @media (max-width: #{$screen-sm-width}) {
    @content;
  }
}
