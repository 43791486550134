@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/typography.scss';

.footer {
  padding: 0 32px 32px;

  @include media-sm {
    padding: 0 20px 24px;
  }
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  @include media-sm {
    flex-direction: column;
  }
}

.blockFirst {
  max-width: 360px;
  margin-right: 48px;

  @include media-md {
    max-width: 320px;
    margin-right: 24px;
  }

  @include media-sm {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.blocks {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;

  @include media-sm {
    justify-content: flex-start;
  }
}

.block {
  max-width: 178px;
  min-width: 90px;
  margin-right: 100px;

  @include media-md {
    margin-right: 24px;
  }
}

.blockLast {
  position: relative;
  display: flex;

  @include media-sm {
    flex-direction: column;
  }
}

.blockLast .block {
  @include media-sm {
    margin-right: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footerLogo {
  background-image: url('../../assets/images/logos/logo-full.svg');
  background-position: center;
  max-width: 339px;
  width: 100%;
  height: 29px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 16px;

  @include media-sm {
    margin-bottom: 8px;
  }
}

.footerText {
  @include landing--small;
  color: var(--black-500);
  opacity: 0.7;
  margin-bottom: 16px;
}

.footerIconsPayment {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;

  @include media-sm {
    margin-bottom: 24px;
  }
}

.footerIconsPaymentItem {
  background-image: url(../../assets/images/icons/payment.svg);
  width: 72px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
}

.visa {
  background-position: 0 0;
}

.mastercard {
  background-position: -72px 0;
}

.amex {
  background-position: -216px 0;
}

.footerIconsSocial {
  display: flex;
  align-items: center;
  gap: 16px;
  transition: opacity 200ms;

  & a {
    display: block;
    &:hover {
      opacity: 0.8;
      transition: opacity 200ms;
    }
  }
}

.socialLink {
  background-image: url(../../assets/images/icons/social-icons.svg);
  width: 30px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
}

.facebook {
  background-position: 0 0;
}

.instargam {
  background-position: -30px 0;
}

.tiktok {
  background-position: -60px 0;
}

.footerTitle {
  @include landing--body-caps;
  color: var(--black-500);
  margin-bottom: 16px;

  @include media-sm {
    margin-bottom: 8px;
  }
}

.footerAddressList {
  @include landing--small;
  line-height: 28px;
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--black-500);
  opacity: 0.7;
}

.footerAddressListItem {
  display: flex;
  align-items: flex-start;
  gap: 0 8px;

  & img {
    margin-top: 8px;
  }
}

.footerLink {
  @include landing--small;
  display: block;
  color: var(--black-500);
  text-decoration: none;
  opacity: 0.7;
  transition: opacity 200ms;
  margin-bottom: 4px;

  &:last-child {
    margin: 0;
  }

  &:hover {
    opacity: 1;
    transition: opacity 200ms;
  }
}

.copy {
  @include landing--small;
  width: 100%;
  text-align: center;
  color: var(--black-500);
  opacity: 0.4;
  margin-top: 48px;

  @include media-sm {
    margin-top: 24px;
  }
}

.displayBlock {
  display: block;
}
