@import '../../../../assets/styles/sizes';

.item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin-bottom: 8px;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }
}

.selectable {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;

  & .price {
    @include media-sm {
      margin-right: 56px;
    }
  }

  & .box {
    width: calc(100% - 128px);
  }
}

.return {
  @include media-sm {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.checkbox {
  height: 28px;
  width: 28px;
  border: 1px solid var(--gray-300);
  border-radius: 50%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 28px;
    width: 28px;
    background-image: url(../../../../assets/images/logos/logo-mini.svg);
    top: -1px;
    left: -1px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: none;
  }

  @include media-sm {
    position: absolute;
    right: 40px;
  }
}

.image {
  width: 72px;
  height: 72px;
  margin-right: 16px;
  @include media-sm {
    width: 50px;
    height: 50px;
  }
}

.title {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.08em;
  color: var(--black-500);
}

.text {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.08em;
  color: var(--black-500);
}

.price {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin-right: 24px;
  @include media-sm {
    margin-right: 0;
  }
}

.box {
  width: calc(100% - 88px);

  @include media-sm {
    position: relative;
  }
}

.itemActive {
  border-color: var(--black-300) !important;
  background-color: var(--gray-100);

  .checkbox::after {
    display: block;
  }
}

.select {
  @include media-sm {
    width: 100%;
    margin-top: 8px;
  }

  & select {
    font-weight: 500;
    height: 48px;
    padding: 0 16px;
    min-width: 228px;
    border: 1px solid var(--gray-400);
    margin-bottom: 0;
    background-position-y: calc(1em + 4px);
    cursor: pointer;
  }
}
