.container {
  max-width: 990px;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }
}

.icon {
  height: 42px;
  width: 42px;
  background-image: url(../../../../assets/images/icons/how-it-works.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.chat {
  background-position: -42px 0;
}

.clothes {
  background-position: -84px 0;
}

.credit {
  background-position: -126px 0;
}

.return {
  background-position: -168px 0;
}

.order {
  background-position: -252px 0;
}

.loadingContainer {
  position: relative;
  height: 160px;
}

.cardStepContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 767px) {
    gap: 8px;
  }
}

.thanks {
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.08em;

  @media (max-width: 767px) {
    display: block;
  }
}

.summary {
  width: 620px;
  margin: 32px auto 16px;
  padding: 16px 24px;
  display: flex;
  border: 1px solid var(--gray-300);
  box-sizing: border-box;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    text-align: center;
    line-height: 30px;
    padding: 8px;
    margin-bottom: 32px;
  }
}

.box {
  margin: 0 15% 0 0;
  padding: 0;
  list-style: none;

  @media (max-width: 767px) {
    margin-right: 0;
  }
}

.fontMedium {
  font-weight: 500;
}

.link {
  color: var(--black-500);
  font-weight: bold;
  text-decoration: underline;
  margin-left: 8px;

  &:hover {
    opacity: 0.9;
  }
}
