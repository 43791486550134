.wrapper {
  width: 100%;
}

.container {
  max-width: 380px;
  margin: 0 auto;
}

.radioCardWrapper {
  margin-bottom: 12px;
}

.createNewStylingProfileButton {
  margin-bottom: 24px;
}

.logoutText {
  font-size: 14px;
  line-height: 17px;
  color: var(--gray-500);
  text-align: center;
}

.logoutLink {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--gray-500);
  }
}
