@import '../../assets/styles/sizes';

.column {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    padding-bottom: 8px;
    @include media-sm {
      margin-bottom: inherit;
    }
  }

  .input {
    width: 100%;
  }
}

.row {
  display: flex;

  .label {
    flex: 0 0 25%;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    color: var(--black-500);
    padding-bottom: 8px;
    line-height: 17px;
    @include media-sm {
      flex: inherit;
      width: 100%;
    }
  }

  .input {
    flex: 0 0 75%;
    @include media-sm {
      flex: inherit;
      width: 100%;
    }
  }

  @include media-sm {
    flex-direction: column;
  }
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
