.state {
  border-radius: 40px;
  padding: 2px 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.12em;
  color: var(--white);
  text-transform: uppercase;
  display: inline;
}

.stateSubmitted {
  background-color: gold;
}

.stateReadyForPicking {
  background-color: var(--orange-300);
}

.stateDispatched {
  background-color: var(--orange-500);
}

.stateDelivered {
  background-color: var(--green-300);
}

.statePendingPayment {
  background-color: var(--blue-300);
}

.statePaymentOverdue {
  background-color: var(--red-500);
}

.statePendingReturn {
  background-color: var(--blue-500);
}

.stateReturnOverdue {
  background-color: var(--red-500);
}

.stateDeliveryOverdue {
  background-color: lightcoral;
}

.stateCancelled {
  background-color: lightgrey;
}

.stateFullyReturned {
  background-color: grey;
}

.stateCompleted {
  background-color: var(--black-500);
}
