.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  color: red;
  font-weight: 500;
}

.reason {
  font-size: 13px;
}
