@import '../../assets/styles/sizes';

.row {
  display: flex;

  @include media-xl {
    flex-direction: column;
  }
}

.firstColumn {
  width: 30%;

  @include media-xl {
    width: 100%;
  }
}

.secondColumn {
  width: 70%;

  @include media-xl {
    width: 100%;
  }
}
