@import '../../assets/styles/sizes.scss';

.container {
  position: relative;
  width: 100%;
}

.item {
  width: 280px;

  @include media-sm {
    width: 232px;
  }
}

.imageUrl {
  position: relative;
  width: 100%;
  height: 280px;
  border-radius: 4px;
  border: 1px solid var(--gray-300);

  @include media-sm {
    height: 232px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 4px;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  font-size: 14px;
  margin-top: 8px;

  @include media-sm {
    padding: 0 8px 8px;
  }
}

.box {
  display: flex;
}

.customerPrice {
  font-weight: 500;
  color: var(--black-500);
  margin-left: 8px;
}

.text,
.originalPrice {
  color: var(--gray-500);
}

.originalPrice {
  margin-left: 8px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: var(--gray-500);
    top: calc(50% - 1px);
    left: 0;
  }
}

.sliderButtonPrev,
.sliderButtonNext {
  width: 40px;
  height: 40px;
  background-image: url('/assets/images/icons/reviews-icon.svg');
  background-size: cover;
  position: absolute;
  top: calc(50% - 31px);
  z-index: 10;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  @include media-sm {
    display: none;
  }
}

.sliderButtonPrev {
  left: -48px;
  background-position: -42px 0;
}

.sliderButtonNext {
  right: -48px;
}

.swiperSlide {
  width: 280px !important;

  @include media-sm {
    width: 232px !important;
  }
}

.typeBadge {
  position: absolute;
  right: 12px;
  top: 8px;
  padding: 2px 8px;
  background-color: var(--black-500);
  border-radius: 20px;
  color: var(--white);
  text-transform: uppercase;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
}
