.subtitle {
  font-family: var(--secondary-font);
  font-size: 18px;
  letter-spacing: 1.8px;
  line-height: 41px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description {
  font-size: 16px;
  letter-spacing: 1.33px;
  line-height: 30px;
  margin-bottom: 30px;
}

.itemsHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  @media (max-width: 1280px) {
    display: none;
  }
}
