@import '../../../../assets/styles/sizes.scss';

.multilineTextInput {
  display: none;
  @include media-sm {
    margin-top: 16px;
    display: block;
    & > textarea {
      width: 100% !important;
      padding: 0;
    }
  }
}

.multilineTextInput textarea {
  max-height: 96px;
}

.multilineTextInput textarea::placeholder {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
  padding: 8px 13px;
}
