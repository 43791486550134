@import '../../../../assets/styles/sizes';

.imagesContainer {
  display: flex;
  margin-bottom: 40px;
  @include media-sm {
    flex-direction: column;
    margin-bottom: 16px;
  }
}

.textOptionContainer {
  max-width: 580px;
  margin: 0 auto;
  @include media-md {
    max-width: 100%;
  }
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  font-family: var(--secondary-font);
  font-weight: 300;
  font-size: 26px;
  line-height: 28px;
  color: var(--black-500);

  @include media-sm {
    flex-direction: column;
    padding: 16px 0;
    font-size: 24px;
    border: 1px solid var(--gray-400);
  }
}

.text {
  margin-right: 16px;

  @include media-sm {
    margin-right: 0;
    margin-bottom: 2px;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link {
  border-bottom: 1px solid var(--black-500);
  margin-right: 8px;
  user-select: none;
  &:hover {
    opacity: 0.9;
  }
}

.textarea {
  display: none;
  resize: none;
  border: 1px solid var(--black-500);
  width: 580px;
  height: 194px;
  padding: 8px;
  outline: none;
  font-size: 16px;
  line-height: 22px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--gray-400);
  }

  @include media-sm {
    width: 100%;
    margin-top: 8px;
  }
}

.textareaActive {
  display: block;
}

.arrowActive {
  transform: rotate(180deg);
}
