@import '../../assets/styles/sizes';

.content {
  padding-bottom: 120px;
}

.imagesContainer {
  display: flex;
  margin-bottom: 32px;

  @include media-sm {
    margin-bottom: 16px;
  }
}

.image {
  width: 50%;

  @include media-sm {
    width: 100%;

    &:first-child {
      display: none;
    }
  }
}

.title {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: var(--black-500);
  margin: 0;
  padding: 32px 0;
  text-align: center;

  @include media-sm {
    font-size: 22px;
    line-height: 32px;
    padding: 16px 0;
  }
}

.text {
  font-size: 18px;
  line-height: 32px;
  color: var(--gray-500);
}
