@import '../../assets/styles/sizes.scss';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  text-align: center;
  padding-top: 48px;
  margin-bottom: 16px;
}

.title {
  font-family: var(--secondary-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
  letter-spacing: 6.67px;
  text-transform: uppercase;
  margin: 0;
  z-index: 2;

  @include media-sm {
    font-size: 28px;
    letter-spacing: 4px;
  }
}

.titleBackground {
  font-family: var(--secondary-font);
  font-size: 72px;
  font-weight: 600;
  position: absolute;
  display: none;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  line-height: 36px;
  letter-spacing: 6px;
  text-transform: uppercase;
  color: var(--white);
  z-index: 1;
  user-select: none;
  text-shadow: -1px -1px 0 #e3e3e3, 1px -1px 0 #e3e3e3, -1px 1px 0 #e3e3e3, 1px 1px 0 #e3e3e3;

  @include media-sm {
    display: none !important;
  }
}

.description {
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  letter-spacing: 1.33px;
}

.number {
  font-family: var(--secondary-font);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
  z-index: -1;
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: 140px;
  letter-spacing: 16.67px;
  color: var(--white);
  text-shadow: -1px -1px 0 #e3e3e3, 1px -1px 0 #e3e3e3, -1px 1px 0 #e3e3e3, 1px 1px 0 #e3e3e3;
}

.dots {
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  top: 0;
  z-index: -2;
}

// modifiers

.minHeight {
  min-height: 178px;
}

.titleLarge {
  font-size: 36px;
  letter-spacing: 10px;
  @media (max-width: 767px) {
    letter-spacing: 6.67px;
    font-size: 28px;
  }
}

.titleTranslate {
  transform: translate(-50%, -100%);
}

.active {
  display: block;
}

.dark {
  background-color: var(--black-500);
}

.dark > .title,
.dark > .description {
  color: var(--white);
}

.dark > .titleBackground {
  text-shadow: -1px -1px 0 var(--black-400), 1px -1px 0 var(--black-400),
    -1px 1px 0 var(--black-400), 1px 1px 0 var(--black-400);
  color: var(--black-500);
  line-height: 70px;
  top: calc(50% + 70px);
}
