@import '../../assets/styles/sizes.scss';

.loadingBox {
  position: relative;
  height: 200px;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.box {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @include media-xl {
    max-width: 676px;
  }

  @include media-md {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
}

.subtitle {
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.12em;
  color: var(--black-500);
  margin: 16px 0;
}

.hiddenSm {
  display: block;

  @include media-sm {
    display: none;
  }
}

.emptyWrapper {
  margin-top: 32px;

  @include media-sm {
    margin-top: 24px;
  }
}

.logout {
  display: none;
  margin-top: 24px;
  button {
    width: 100%;
  }

  @include media-md {
    display: block;
  }
}

.mobileSubmenu {
  display: none;

  @include media-md {
    display: block;
    margin-bottom: 16px;
    border-right: none;

    & > div {
      display: none;
    }

    a {
      background-color: var(--white);
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        border-top: 1px solid var(--gray-300);
      }
      &:nth-child(5) {
        border-bottom: 0;
      }
    }
  }
}
