@import '../../assets/styles/sizes';

.textArea {
  resize: none;

  @include media-sm {
    padding: 9px 20px 9px 16px; // TODO we really need it?
  }
}

.label {
  flex: 0 0 25%;
  font-size: 14px;
  line-height: 17px;
  color: var(--black-500);
  margin-bottom: 8px;
  font-weight: 500;
}
