@import '../../../../assets/styles/sizes';

.container {
  display: flex;
  justify-content: center;
  padding: 0px 0 56px 0;

  @include media-sm {
    padding: 0px 0 32px 0;
    flex-direction: column;
  }
}

.button {
  margin-bottom: 0px;
  @include media-sm {
    margin-bottom: 10px;
  }
}

.login {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: var(--gray-500);
  margin-right: 32px;
  flex-shrink: 0;

  @include media-sm {
    display: none;
  }
}

.link {
  color: var(--gray-500);
  margin-left: 8px;
}

.selectMessage {
  display: none;

  @include media-sm {
    color: var(--gray-500);
    font-weight: 500;
    text-align: center;
    display: block;
  }
}
