.button {
  min-width: 50px;
  height: 30px;
  padding: 0 14px;
  border: solid 1px var(--black-500);
  background-color: white;
  color: var(--black-500);
  font-family: var(--primary-font);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &:focus {
    outline: none;
  }
  &__selected {
    border: none;
    background-color: var(--black-500);
    color: white;
  }
}
