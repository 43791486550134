@import '../../assets/styles/sizes.scss';

.host {
  width: 330px;
  height: 330px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 0 16px;
  text-align: center;

  @include media-xl {
    width: 300px;
    & button {
      padding: 0 16px;
    }
  }

  @include media-md {
    width: 100%;
    padding: 16px;
  }
}
