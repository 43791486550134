@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/typography';

.section {
  position: relative;
  padding: 48px 32px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 306px;
    height: 16px;
    background-image: url(../../../../assets/images/icons/bottom-line.svg);
    background-repeat: no-repeat;
  }

  @include media-sm {
    padding: 56px 20px 32px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.mainTitle {
  @include landing--h2;
  text-align: center;
  color: var(--black-500);
  margin-top: 0;
  margin-bottom: 32px;

  @include media-sm {
    margin-bottom: 24px;
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  gap: 32px;

  @include media-sm {
    gap: 32px 24px;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  }
}

.cardTitle {
  @include landing--body;
  font-weight: 600;
  color: var(--black-300);
  margin-top: 8px;
  min-height: 48px;
  max-width: 145px;
}

.cardTitleNumber {
  display: none;

  @include media-sm {
    display: inline;
    margin-right: 4px;
  }
}

.cardText {
  @include landing--small;
  color: var(--gray-500);
  margin-top: 8px;
}

.image {
  height: 50px;
  width: 50px;
  background-image: url(../../../../assets/images/icons/how-it-works.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.complete {
  background-position: 0 0;
}

.box {
  background-position: -300px 0;
}

.clothes {
  background-position: -100px 0;
}

.creditCardIcon {
  background-position: -150px 0;
}

.returnIcon {
  background-position: -200px 0;
}

.clickIcon {
  background-position: -250px 0;
}

.TrustedShopsWrapper {
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translateX(-50%);

  @include media-sm {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
