.item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
  @media (max-width: 1280px) {
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0;
  }
}

.image {
  width: 73px;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 20px;

  & img {
    width: 100%;
  }

  &_mock {
    width: 50%;
    height: 73px;
    background-color: var(--gray-200);
  }

  @media (max-width: 1280px) {
    width: 60%;
    height: auto;
    margin-right: 0;
    margin-bottom: 10px;
    & img {
      width: 100%;
      padding-bottom: 3px;
    }
  }
}

.category {
  align-items: center;
  width: 65%;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.3px;
  color: var(--gray-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
  }
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.quantity {
  margin-left: 8px;
  color: var(--gray-500);
  font-weight: 300;
  font-size: 8px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1280px) {
    margin-left: 0;
    width: 80%;
  }
}

.size {
  width: 20%;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1.3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px;
  @media (max-width: 1500px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media (max-width: 1280px) {
    width: 80%;
    line-height: 30px;
    padding: 0;
  }
}
