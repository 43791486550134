.container {
  width: 100%;
  position: relative;
  font-size: 12px;
  letter-spacing: 1.3125px;
  transition: 400ms;
  cursor: pointer;

  &:hover {
    transition: 400ms;
  }

  &.error {
    background-color: var(--white);
    border: 1px solid #d8000c;

    &:hover {
      background-color: var(--gray-100);
    }
  }
}

.wrapper {
  display: flex;
  padding-left: 10px;
}

.errorIcon {
  width: 15px;
}

.message {
  padding: 10px;
  line-height: 20px;
}

.closeBtn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  img {
    display: block;
    width: 20px;
    height: 20px;
    color: var(--black-500);
  }
}
