@import '../../assets/styles/sizes';

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 32px;
  border-bottom: 1px solid #e0e2e2;
  background-color: white;
  min-height: var(--header-height);
  max-height: var(--header-height);
  z-index: 3;

  @include media-sm {
    padding: 0 20px;
    border: none;
    box-shadow: 0 2px 16px rgb(108 108 108 / 12%);
  }
}

.navigationItems {
  display: flex;
  transition: 200ms;
  justify-content: center;
  position: relative;
  min-height: var(--header-height);
  opacity: 0;
}

.menuShow {
  opacity: 1;
  transition: opacity 0.06s;

  & > a {
    pointer-events: all;
  }
}

.navigationItem {
  display: flex;
  align-items: center;
  min-width: 44px;
  min-height: 100%;
  margin-right: 40px;
  color: var(--black-500);
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  pointer-events: none;
  position: relative;
  transition: font-weight 0.05s;

  &:hover {
    font-weight: 600;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      background: var(--black-500);
    }
  }

  &:focus-visible {
    outline: none;

    & span {
      outline: 2px solid var(--blue-200);
    }
  }

  &:active {
    font-weight: 600;

    & span {
      outline: none;
    }
  }

  &_selected {
    font-weight: 600;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      background: var(--black-500);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  @include media-sm {
    margin-right: 12px;
  }
}

.account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 80px;
  z-index: 10;
}

.rightMenu {
  min-height: var(--header-height);
  display: flex;
}

.logoLink {
  margin-right: 40px;
  z-index: 10;
  transition: 200ms;

  &:hover {
    opacity: 0.8;
    transition: 0ms;
  }

  @include media-md {
    margin-right: 20px;
  }

  @include media-sm {
    margin-right: 0;
  }
}

.logo {
  background-image: url('../../assets/images/logos/logo-short.svg');
  background-size: contain;
  width: 161px;
  height: 30px;

  @include media-sm {
    width: 130px;
    height: 24px;
  }
  @media screen and (max-width: 350px) {
    width: 30px;
    height: 30px;
    background-image: url('../../assets/images/logos/logo-mini.svg');
    background-repeat: no-repeat;
  }
}

.logoText {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: var(--black-500);

  @include media-md {
    display: none;
  }

  &_item {
    &:first-child {
      opacity: 0.5;
    }
  }
}

.linkBtnsBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
