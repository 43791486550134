.card {
  width: 100%;
  padding: 24px 16px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  background-color: var(--white);
  border: 1px solid #333333;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: background-color 0.15s ease;

  &:last-child {
    margin: 0;
  }
}

.cardSelected {
  background-color: var(--gray-100);
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  background-image: url('/assets/images/icons/man.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.boyImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  background-image: url('/assets/images/icons/boy.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.girlImage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 48px;
  height: 48px;
  background-image: url('/assets/images/icons/girl.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.name {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: var(--black-500);
  span {
    color: var(--gray-400);
  }

  margin-bottom: 6px;
}

.age {
  display: inline-block;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.12em;
  color: var(--black-500);
  padding: 2px 8px;
  border: 1px solid var(--black-500);
  border-radius: 40px;
}

.checkbox {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  border: 1px solid var(--black-300);

  &.checkboxSelected {
    background-image: url(/assets/images/icons/radio.svg);
    background-size: cover;
  }
}
