@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/typography';

.section {
  position: relative;
  padding: 0 32px;
  margin-bottom: 64px;

  @include media-sm {
    padding: 0;
    margin: 0;
  }
}

.video {
  display: block;
  width: 100%;
  max-width: 1200px;
  max-height: 730px;
  margin: 0 auto;
  object-fit: fill;
  background-color: var(--gray-100);

  @include media-lg {
    max-width: 100%;
  }
}

.playIcon {
  margin-right: 16px;
}

.button {
  display: flex;
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @include landing--body-caps;
  color: white;
  padding: 16px 24px;
  text-transform: uppercase;
  border: 0;
  background-color: var(--black-400);
  border-radius: 2px;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--black-500);
  }
}
