.signup {
  :global {
    .auth0-lock.auth0-lock {
      z-index: 0;
    }
    @media (min-width: 481px) {
      .auth0-lock.auth0-lock .auth0-lock-center {
        padding: 0 40px 40px 40px;
      }
    }
    .auth0-lock-header {
      height: 52px;
      background-color: white;
    }
    .auth0-lock.auth0-lock .auth0-lock-tabs li {
      font-size: 20px;
      font-family: var(--secondary-font);
    }
    .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current span {
      color: var(--black-500);
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}
