@import '../../assets/styles/sizes';

.root {
  margin-bottom: 16px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-sm {
    flex-direction: column;
    align-items: flex-start;
  }
}

.errorContainer {
  height: 30px;
  line-height: 30px;
  @include media-sm {
    margin-left: 0;
  }
}

.label {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--black-500);
  font-weight: 500;
}

.inputBlock {
  width: 100%;
  display: flex;
  gap: 5px;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
}

.input {
  width: 100%;
  height: 48px;
}

.errorMessageContainer {
  color: red;
  font-size: 12px;
}
