@import '../../assets/styles/sizes';

.wrapper {
  margin-bottom: 48px;
  padding: 0 32px;

  @include media-md {
    margin-bottom: 24px;
  }

  @include media-sm {
    padding: 0 20px;
  }
}

.container {
  max-width: 1120px;
  margin: 0 auto;

  @include media-md {
    max-width: 880px;
  }

  @include media-sm {
    max-width: 100%;
  }
}

.box {
  position: relative;
  min-height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-md {
    flex-direction: column;
    align-items: baseline;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 48px;

  @include media-md {
    margin: 0;
  }
}

.title {
  font-family: var(--secondary-font);
  margin: 0;
  font-weight: 300;
  font-size: 50px;
  line-height: 54px;
  display: flex;
  align-items: center;
  color: var(--black-500);
  @include media-md {
    font-size: 36px;
    line-height: 40px;
    padding-top: 0;
  }
}

.stylist {
  @include media-md {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
  }
}

.stylistImageWrapper {
  width: 120px;
  height: 120px;
  background-color: var(--white);
  padding: 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin-bottom: 16px;
  @include media-md {
    margin-bottom: 0;
    width: 56px;
    height: 56px;
  }
}

.stylistImage {
  width: 100%;
  border-radius: 50%;
}

.stylistText {
  text-align: center;
  @include media-md {
    text-align: left;
  }
}

.stylistName {
  font-family: var(--secondary-font);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin-bottom: 8px;
}

.stylistFashion {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: var(--black-500);
  opacity: 0.7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

.message {
  position: relative;
  max-width: 800px;
  font-size: 20px;
  line-height: 32px;
  color: var(--gray-500);
  border: 1px solid #b9bec1;
  padding: 12px 52px 8px 24px;
  margin-top: 24px;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -20px;
    height: 21px;
    width: 21px;
    background-image: url(../../assets/images/stylists/message-triangle.svg);
    background-size: contain;
    background-repeat: no-repeat;
    @include media-md {
      top: -20px;
      left: -1px;
      transform: rotate(90deg);
    }
  }

  @include media-md {
    font-size: 14px;
    line-height: 20px;
    padding: 12px 12px 4px 12px;
  }
}

.displayNone {
  display: none;
}

.stylistWithoutMessage {
  margin-bottom: 24px;

  .stylist {
    // position: absolute;
    // left: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    margin-right: 32px;

    @include media-sm {
      position: static;
    }

    @include media-md {
      margin-right: 16px;
    }
  }

  .info {
    // width: 100%;
    // align-items: center;
    margin-left: 0;

    @include media-lg {
      width: auto;
      // margin-left: 48px;
    }

    @include media-md {
      // margin-left: 0;
    }
  }

  .stylistImageWrapper {
    width: 82px;
    height: 82px;

    @include media-md {
      width: 56px;
      height: 56px;
    }
  }

  .stylistText {
    text-align: left;
  }

  .stylistImageWrapper {
    margin-bottom: 0;
  }

  .box {
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @include media-sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
