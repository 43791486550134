@import '../../assets/styles/sizes';

.nav {
  position: relative;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-width: 0 1px 1px 0;

  @include media-md {
    display: none;
  }
}

.item {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: var(--secondary-font);
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: var(--black-500);
  border-bottom: 1px solid var(--gray-300);
  padding: 16px 24px;
  transition: 200ms;

  &:first-child {
    margin-top: 24px;
  }

  &.active,
  &:hover {
    background-color: var(--gray-200);
    transition: 0ms;
  }
}

.icon {
  display: flex;
  margin-right: 24px;
}

.invert {
  filter: invert(100%);
}

.logout > button {
  position: absolute;
  left: 24px;
  margin: 0;
  top: calc(100vh - 160px);
  width: calc(100% - 48px);
  background-color: var(--gray-100);

  &:hover {
    background-color: var(--gray-200);
  }
}
