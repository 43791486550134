@import '../../../../../../../assets/styles/sizes';

.box {
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 16px 0;
}

.talk {
  max-width: 368px;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin: 0 auto;
  padding-bottom: 16px;
}

.button {
  margin: 0 auto;
}
