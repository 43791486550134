@import '../../assets/styles/sizes';

.wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.footer {
  height: 426px;
}

.container {
  justify-self: center;
  align-self: center;
  width: var(--content-width);
  margin: 20px auto;

  @include media-sm {
    padding: 20px 20px;
  }
}

.boxSamplesWrapper {
  padding: 0 48px 64px;

  @include media-sm {
    padding: 0 0 64px;
  }
}

.boxSamplesSection {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #b9bec1;
  padding-top: 32px;
}

.boxSamplesTitle {
  font-family: var(--secondary-font);
  font-weight: 300;
  font-size: 26px;
  line-height: 28px;
  color: var(--black-500);
  margin: 0;

  @include media-sm {
    text-align: center;
  }
}

.boxSamplesSubtitle {
  font-size: 16px;
  line-height: 30px;
  color: var(--gray-500);
  margin-top: 2px;
  margin-bottom: 16px;

  @include media-sm {
    text-align: center;
  }
}

@include media-sm {
  :global {
    body {
      overflow-y: auto;
    }

    .wrapper {
      height: auto;
    }

    .main {
      flex: 1 1 auto;
      overflow-y: visible;
    }
  }
}

@media screen {
  html {
    --content-width: 1200px;
  }
}

@include media-xl {
  html {
    --content-width: 1024px;
  }
}

@include media-lg {
  html {
    --content-width: 970px;
  }
}

@include media-md {
  html {
    --content-width: 750px;
  }
}

@include media-sm {
  html {
    --content-width: 100%;
  }
}
