@import '../../assets/styles/sizes';

.container {
  margin-bottom: 31px;
  @include media-sm {
    margin-bottom: 21px;
  }
}

.radioContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radioBtn {
  display: inline-block;
  input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input[type='radio']:checked + label {
    background-color: var(--black-500);
    color: white;
  }

  input[type='radio']:focus-visible + label {
    outline: 2px solid var(--blue-200);
  }

  label {
    min-width: 50px;
    height: 30px;
    padding: 0 8px;
    border: solid 1px var(--black-500);
    background-color: white;
    color: var(--black-500);
    font-family: var(--primary-font);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.13px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    text-align: center;
  }
}
