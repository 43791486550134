@import '../../assets/styles/sizes';

.base {
  position: relative;

  padding: 8px 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  text-decoration: none;
  font-family: var(--primary-font);
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.size_regular {
  height: 48px;
  line-height: 20px;
  font-size: 12px;
  letter-spacing: 0.08em;
}

.size_small {
  padding: 16px 0;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.08em;
}

.size_medium {
  padding: 16px 24px;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  letter-spacing: 0.08em;
}

.size_large {
  height: 70px;

  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1.875px;

  @include media-sm {
    font-size: 16px;
  }
}

.color_white {
  border: solid 1px var(--black-500);
  background-color: white;
  color: var(--black-500);
  transition: 200ms;

  &:focus,
  &:hover {
    background-color: var(--gray-100);
    transition: 0ms;
  }
}

.color_black {
  border: solid 1px transparent;
  background-color: var(--black-500);
  color: white;
  transition: 200ms;

  &:focus,
  &:hover {
    background-color: var(--black-300);
    transition: 0ms;
  }
}

.width_full {
  width: 100%;
}

.width_wide {
  min-width: 184px;
}
