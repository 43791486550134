.select {
  position: relative;
  height: 44px;
  border-color: var(--gray-300);
  width: 100%;
  margin-bottom: 16px;
  padding: 0 12px;

  appearance: none;
  background-image: url(../../assets/images/icons/arrowSelector.svg);
  background-position: calc(100% - 12px) calc(1em + 2px), calc(100% - 12px) calc(1em + 2px), 100% 0;
  background-repeat: no-repeat;
  &:focus {
    outline: none;
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  padding-bottom: 8px;
  color: var(--black-500);
}

.isOpened {
  background-image: url(../../assets/images/icons/arrowSelectorDown.svg);
}
