.makeDefaultButton {
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.08em;
  height: auto;
}

.badge {
  font-family: var(--primary-font);
  background-color: var(--gray-400);
  padding: 2px 9px;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
}
