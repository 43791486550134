.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 55px;
  @media (max-width: 992px) {
    justify-content: center;
  }
}

.flexBox {
  width: 75%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.title {
  font-family: var(--secondary-font);
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 1.875px;
  color: var(--black-500);
  margin-top: 0;
  margin-bottom: 30px;
  @media (max-width: 480px) {
    & br {
      display: none;
    }
  }
}
