.form {
  max-width: 634px;
  margin: 0 auto;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  margin-top: 40px;
}
