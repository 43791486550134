@import '../../../../assets/styles/sizes';

.button {
  width: 30%;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin: 0 auto 67px auto;

  @include media-sm {
    width: 100%;
  }
}
