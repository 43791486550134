@import '../../assets/styles/sizes';

.back {
  display: none;

  @include media-md {
    color: var(--black-500);
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: left;
  }
}

.arrow {
  transform: rotate(90deg);
  margin-right: 9px;
}
