@import '../../assets/styles/sizes';

.wrapper {
  display: grid;
  grid-template-columns: 336px 1fr;
  padding-bottom: 64px;

  @include media-md {
    display: flex;
    flex-direction: column;
  }
}

.content {
  min-height: var(--profile-height);
  padding: 32px 40px;

  @include media-xl {
    padding: 24px;
  }
}
