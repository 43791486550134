@import '../../../../assets/styles/sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 32px;
  margin-right: 32px;
  width: 50%;
  position: relative;

  @include media-sm {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.progressBar {
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--gray-200);
  height: 5px;
}

.progressLine {
  height: 20px;
  // background-color: var(--black-500);
  background-color: var(--gray-500);
  transition: transform 0.2s ease;
  transform-origin: left;
}

.logo {
  position: absolute;
  display: flex;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  padding: 4px;
  transition: transform 0.2s ease;
  transform-origin: center;
}

.stepNumber {
  position: relative;
  font-family: var(--secondary-font);
  font-weight: 800;
  font-size: 18px;
  line-height: 12px;
  // color: var(--gray-500);
  color: var(--black-500);
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-left: 10px;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}
