.host {
  padding: 5px 6px 3px 6px;
  display: inline-block;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.9375px;
  text-transform: uppercase;
  color: var(--white);
  border-radius: 9px;
  background-color: var(--black-500);
}
