@import '../../../../assets/styles/sizes';

.wrapper {
  display: flex;
}

.host {
  margin: 0 auto;
  max-width: 600px;
  align-self: center;
  justify-self: center;
  text-align: center;
  @include media-sm {
    text-align: left;
    padding: 16px;
    border: 1px solid var(--gray-300);
  }
}

.buttons {
  max-width: 344px;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  & > button {
    margin: 0 16px 0 0;
    &:last-child {
      margin: 0;
    }
  }

  @include media-sm {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > button {
      margin: 0 0 8px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}

.header {
  margin: 0 0 8px 0;
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 1.88px;
  line-height: 34px;
}

.subtitle {
  margin-bottom: 24px;
}
