@import '../../../../assets/styles/sizes';

.button {
  border: 0;
  padding: 0;
  background: transparent;
  width: 100%;
  position: relative;
  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  &:focus {
    outline: none;
  }

  @include media-sm {
    margin-right: 0;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.checkboxInput {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;

  &:focus-visible + .container {
    outline: 2px solid var(--blue-200);
  }
}

.container {
  display: block;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    & .imageHover {
      opacity: 1;
    }

    @include media-sm {
      & .imageHover {
        opacity: 0;
      }
    }
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 257px;

  @include media-md {
    height: 200px;
  }
}

.overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.overlayActive {
  display: flex;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.imageHover {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: opacity 0.4s ease;
}

.box {
  padding: 16px;

  @include media-md {
    padding: 8px;
  }
  @include media-sm {
    padding-bottom: 16px;
    border: 1px solid var(--gray-300);
  }
}

.boxActive {
  background-color: var(--black-200);
  & > .title,
  .text {
    color: var(--white);
    border-color: var(--white);
  }

  @include media-sm {
    border-color: var(--black-200);
  }
}

.title {
  font-family: var(--secondary-font);
  font-weight: 300;
  font-size: 26px;
  line-height: 28px;
  color: var(--black-500);
  margin-top: 8px;
  display: inline-block;
  border-bottom: 1px solid var(--black-500);
  margin-bottom: 8px;

  @include media-sm {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 4px;
  }
}

.text {
  font-size: 16px;
  line-height: 22px;
  color: var(--gray-500);
  max-width: 270px;

  @include media-sm {
    font-size: 14px;
    line-height: 20px;
  }
}
