@import '../../../../assets/styles/sizes';

.content button {
  margin-top: 30px;
}

.invert > svg {
  filter: invert(1);
}

.marginTop {
  margin-top: 9px;
}

.button {
  width: 30%;
  margin: 0 auto;
  @include media-md {
    width: 100%;
  }
}
