@import '../../assets/styles/sizes';

.label {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.31px;
  line-height: 30px;
  color: var(--gray-500);

  @include media-sm {
    font-size: 12px;
    font-weight: 600;
  }
}
