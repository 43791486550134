@import '../../../../assets/styles/sizes';

.barometer {
  padding: 0 32px 80px;

  @include media-sm {
    padding: 0 20px 32px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.mainTitle {
  font-family: var(--secondary-font);
  font-size: 42px;
  line-height: 52px;
  font-weight: 300;
  text-align: center;
  color: var(--black-500);
  margin-top: 0;
  margin-bottom: 32px;

  @include media-sm {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

.text {
  max-width: 680px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding-bottom: 56px;
}

.barometerInfo {
  display: flex;
  align-items: center;
  gap: 0 72px;
  margin-bottom: 8px;

  @include media-md {
    gap: 0 24px;
  }

  @include media-sm {
    display: none;
  }
}

.barometerInfoMobile {
  display: none;

  @include media-sm {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    background-image: url(../../../../assets/images/icons/arrowBarometer.svg);
    background-repeat: no-repeat;
    background-size: 8px;
    background-position: left 16px bottom 16px;
  }
}

.infoWrapper {
  background-image: url(../../../../assets/images/icons/arrowBarometer.svg);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: left 16px bottom 16px;
  margin-left: 20%;
}

.infoBox {
  background-image: url(../../../../assets/images/icons/arrowBarometer.svg);
  background-repeat: no-repeat;
  background-size: 8px;
  background-position: right 16px bottom 16px;
  margin-left: 15%;
  padding-bottom: 48px;
}

.infoFirst {
  @include media-sm {
    max-width: 90%;
  }
}

.infoCenter {
  @include media-sm {
    max-width: 80%;
    margin-left: 0;
  }
}

.info {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  flex: 6;
  background-color: var(--white);

  &:nth-child(2) {
    flex: 4;

    &::before {
      content: '';
      background-image: url(../../../../assets/images/icons/arrowBarometer.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom;

      position: absolute;
      bottom: 0;
      left: -20px;
      width: 8px;
      height: 40px;

      @include media-sm {
        display: none;
      }
    }
  }

  &:last-child {
    text-align: right;

    @include media-sm {
      margin-bottom: 0;
    }
  }

  @include media-sm {
    padding-bottom: 16px;
  }
}

.important {
  color: var(--gold-300);
  font-weight: 600;
  display: block;
}

.barometerItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  max-width: 100%;
  height: 40px;
  background-color: var(--black-500);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
  }
  &::before {
    left: 0;
    background: linear-gradient(90deg, #db0707 0%, #ec7201 46.35%, #0d0d0d 98.44%);
    width: 296px;
    height: 40px;

    @include media-sm {
      width: 80px;
    }
  }

  &::after {
    right: 0;
    background: linear-gradient(270deg, #fbba14 61.54%, rgba(251, 186, 20, 0) 100%);
    width: 120px;
    height: 40px;
  }
}

.logo {
  position: absolute;
  display: flex;
  top: -8px;
  left: 40%;
  transform: translateX(-56px);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--white);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  padding: 4px;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--white);

    @include media-sm {
      display: none;
    }
  }

  &::before {
    content: 'Average';
    left: -80px;
  }

  &::after {
    content: '72% Discount';
    right: -120px;
  }

  @include media-sm {
    left: 20%;
    transform: translateX(-15%);
  }
}

.barometerText {
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--white);
  z-index: 1;

  @include media-sm {
    display: none;
  }
}

.barometerItemMobile {
  display: none;

  @include media-sm {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
}

.textMobile {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  &:last-child {
    text-align: right;
  }
}

.block {
  display: block;
}
