@import '../../../assets/styles/sizes';

.textInput {
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.deliveryTimeContainer {
  display: flex;
}

.deliveryTimeLabel {
  flex: 0 0 25%;

  @include media-sm {
    flex: 0;
  }
}

.deliveryTimeText {
  color: var(--gray-500);
}
