@import '../../assets/styles/sizes.scss';

.grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.2fr;
  @include media-md {
    grid-template-columns: 1fr;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  &:first-child {
    min-width: 50%;
  }
  &:last-child {
    min-width: 20%;
    display: flex;
    align-items: flex-end;
  }

  @include media-md {
    gap: 8px;
    min-width: auto;
  }
}

.item {
  padding: 12px 16px;
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  transition: background-color 0.2s;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: var(--gray-200);
    cursor: pointer;
  }

  @include media-sm {
    padding: 8px;
    margin-bottom: 8px;
  }
}

.title {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
  font-weight: 500;
}

.text {
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--black-500);
}

.hiddenMobile {
  display: flex;
  @include media-md {
    display: none;
  }
}

.hidden {
  display: none;
}
