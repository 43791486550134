.card {
  width: 150px;
  background-color: var(--gray-100);
  padding: 8px;
  border-radius: 4px;
}

.cardVisited {
  background-color: var(--white);
  border: 1px solid var(--gray-300);

  & > * {
    opacity: 0.3;
  }
}

.box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.number {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.08em;
}

.title {
  font-family: var(--secondary-font);
  font-size: 15px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0.6px;
}
