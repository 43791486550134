@import '../../../../assets/styles/sizes';

.checkboxInput {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  position: absolute;

  &:focus-visible + .container {
    outline: 2px solid var(--blue-200);
  }
}

.label {
  cursor: pointer;
}

.imageContainer {
  position: relative;
  height: 182px;
  &:hover {
    & .imageHover {
      opacity: 1;

      @include media-sm {
        opacity: 0;
      }
    }
  }
}

.imageOverlay {
  display: none;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.imageOverlayActive {
  display: flex;
}

.badge {
  width: 56px;
  height: 56px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.imageHover {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: opacity 0.4s ease;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-500);
  padding: 8px;

  @include media-sm {
    border: 1px solid var(--gray-300);
  }
}

.titleActive {
  color: var(--white);
  background-color: var(--black-200);

  @include media-sm {
    border-color: var(--black-200);
  }
}
