.questionsContainer {
  max-width: 634px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}

.optionSelectContainer {
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.number {
  &__title {
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 30px;
    }
  }
  margin-bottom: 60px;

  &__title {
    width: 164px;
  }

  &__input {
    width: 109px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.specifyMore {
  color: var(--gray-500);
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: var(--gray-500);
  }
}
