@import '../../../../assets/styles/sizes';

.form {
  width: 80%;
  margin: 0 auto;
  @include media-md {
    width: 100%;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
