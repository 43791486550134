@import '../../assets/styles/sizes';

.title {
  display: flex;
  align-items: center;
  & > span {
    @include media-sm {
      display: none;
    }
  }
}

.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.3125px;
  color: var(--gray-500);
}

.text {
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 1.3125px;
  color: var(--black-500);
  & span {
    text-transform: capitalize;
  }
}

.smallTitle {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
}

.smallText {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--black-500);
}
