:root {
  --white: #ffffff;

  --black-200: #3e3e3e;
  --black-300: #2c292c;
  --black-400: #1c1d20;
  --black-500: #0d0d0d;

  --gray-100: #f7f7f7;
  --gray-200: #ebebeb;
  --gray-300: #e0e0e0;
  --gray-400: #bdbdbd;
  --gray-500: #7c7c7c;

  --blue-gray-100: #f7f9fa;

  --blue-200: #3898ec;
  --blue-300: #0091ff;
  --blue-500: #0069e1;

  --orange-300: #f5a841;
  --orange-500: #f18744;

  --yellow-300: #ffcc48;

  --gold-300: #da9d00;

  --green-300: #7dcf1c;

  --red-500: #f56565;
}
