@import '../../../../assets/styles/sizes.scss';

.loadingBox {
  min-height: 295px;
}

.title {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 24px;
  margin: 0;
  padding-bottom: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-sm {
    padding-bottom: 8px;
  }
}

.text {
  letter-spacing: 0.12em;
  color: var(--black-500);
  line-height: 24px;
  margin-bottom: 24px;
  p {
    margin: 0;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  @include media-sm {
    margin-bottom: 16px;
  }
}

.button {
  margin: 0 auto;
  padding: 16px 32px;
}

.image {
  height: 85px;

  @include media-sm {
    height: 70px;
  }
}

.hidden {
  display: none;
}

.box {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;

  & button {
    width: 48%;
  }
}
