@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/typography';

.section {
  position: relative;
  padding: 0 48px 120px;

  @include media-sm {
    padding: 0 0 16px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  align-items: flex-end;
  text-align: center;

  @include media-sm {
    flex-direction: column-reverse;
  }
}

.title {
  @include landing--h2;
  margin-bottom: 8px;
  margin-top: 0;
}

.subtitle {
  @include landing--body;
  color: var(--gray-500);
  margin-bottom: 48px;

  @include media-sm {
    margin-bottom: 32px;
  }
}
