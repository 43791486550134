@import '../../../../../../assets/styles/sizes';

.infoBox {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-sm {
    margin-bottom: 8px;
  }
}

.title {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
}

.text {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.12em;
  color: var(--black-500);
}

.item {
  display: flex;
  line-height: 30px;
}

.key {
  flex: 0 0 25%;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1.3px;
  color: var(--gray-500);

  @include media-md {
    flex: 0 0 30%;
  }
}

.value {
  flex: 1 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.3px;
  color: var(--black-500);
  display: flex;
  align-items: center;
}

.detailsNavContainer {
  display: none;
  justify-content: space-between;
  margin-bottom: 16px;

  @include media-sm {
    display: flex;
  }
}

.container {
  margin-right: 16px;

  @include media-xl {
    display: flex;
    margin: 0 0 16px;
  }

  @include media-sm {
    display: block;
  }
}

.hiddenContainer {
  @include media-sm {
    display: none;
    background-color: var(--white);
    padding: 20px;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 64px;
    left: 0;
    z-index: 2;
  }
}

.isOpened {
  display: block;
}

.orderInfo {
  padding: 12px 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @include media-xl {
    max-width: 260px;
    width: 100%;
    margin-bottom: 0;
    margin-right: 16px;
  }

  @include media-sm {
    max-width: 100%;
    margin-bottom: 8px;
  }
}

.address {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.08em;
  color: var(--black-500);
  margin-top: 8px;
}

.showOrderDetailsLink {
  font-size: 15px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
}

.backToOrderLinkWrapper {
  display: none;
  margin-bottom: 24px;

  @include media-sm {
    display: flex;
  }
}

.backToOrderLink {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
  cursor: pointer;

  &:hover {
    color: var(--black-100);
  }
}
