@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/typography';

.section {
  position: relative;
  background-color: var(--blue-gray-100);
  max-height: 78vh;
  overflow: hidden;

  @include media-sm {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 0;
    max-height: none;
  }
}

.videoDesktop {
  & video {
    display: block;
    width: 100%;
  }

  @include media-sm {
    display: none;
  }
}

.videoMobile {
  display: none;

  @include media-sm {
    display: block;

    & video {
      display: block;
      width: 100%;
    }
  }
}

.container {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  @include media-sm {
    max-width: 100%;
    padding: 0 20px;
    position: static;
    transform: none;
  }
}

.title {
  @include landing--h1;
  color: var(--white);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

  margin: 0 auto;
  text-align: center;
  padding-bottom: 100px;

  @include media-md {
    max-width: 485px;
  }

  @include media-sm {
    max-width: 320px;
    font-size: 40px;
    line-height: 46px;
    color: var(--black-400);
    text-shadow: none;
    padding-bottom: 50px;
  }
}

.actionPrompt {
  @include landing--h3;

  text-align: center;
  margin: 0 auto;
  color: var(--white);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 32px;
  line-height: 36px;
  font-weight: 600;
  padding-bottom: 16px;

  @include media-md {
    max-width: 415px;
  }

  @include media-sm {
    font-size: 24px;
    line-height: 24px;
    color: var(--black-400);
    text-shadow: none;
    padding-bottom: 16px;
  }
}

.text {
  @include landing--h1;

  text-align: center;
  margin: 0 auto;
  padding-bottom: 16px;
  color: var(--white);
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  line-height: 26px;
  font-weight: 900;
  letter-spacing: 0.08em;

  @include media-md {
    max-width: 415px;
  }

  @include media-sm {
    font-size: 16px;
    line-height: 24px;
    color: var(--black-400);
    text-shadow: none;
    padding-bottom: 8px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  @include media-sm {
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.button {
  @include landing--body-caps;
  display: inline-block;
  text-align: center;
  color: var(--gray-100);
  text-decoration: none;
  padding: 12px 30px;
  border-radius: 2px;
  transition: background-color 0.2s;
  margin-right: 40px;

  &:last-child {
    margin: 0;
  }

  @include media-md {
    margin-right: 24px;
  }

  @include media-sm {
    margin: 0 0 16px;
  }
}

.buttonBlack {
  background-color: var(--black-400);

  &:hover {
    background-color: var(--black-500);
  }
}
