.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 55px;
  @media (max-width: 992px) {
    justify-content: center;
  }
}

.flexBox {
  width: 75%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.title {
  font-family: var(--secondary-font);
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 1.875px;
  color: var(--black-500);
  margin-top: 0;
  margin-bottom: 30px;
  @media (max-width: 480px) {
    & br {
      display: none;
    }
  }
}

.button {
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black-500);
  color: var(--white);
  height: 70px;
  width: 100%;
  cursor: pointer;
  transition: 200ms;
  &:hover {
    opacity: 0.95;
    transition: 0ms;
  }

  &_image {
    margin-right: 20px;
  }

  &_text {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 1.875px;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.small {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1.125px;
  color: var(--gray-500);
  margin-top: 18px;
  margin-bottom: 0;
  &_link {
    color: var(--gray-500);
    padding-left: 5px;
    &:hover {
      color: var(--black-500);
    }
  }

  @media (max-width: 480px) {
    & br {
      display: none;
    }
  }
}

.loadingWrapper {
  height: 302px;
  margin-bottom: 55px;
  position: relative;
}
