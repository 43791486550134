.total {
  border-bottom: 1px solid var(--gray-300);
  margin: 24px 0;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

.item {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.08em;
}
