@import '../../assets/styles/sizes';

.root {
  position: relative;
  margin-bottom: 16px;
}

.row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
  color: var(--black-500);
  padding-bottom: 8px;
  line-height: 17px;
}

.inputBlock {
  width: 100%;
  display: flex;
  gap: 5px;
  @include media-sm {
    flex: 0 1;
    width: 100%;
  }
}

.input {
  width: 100%;
  height: 48px;

  @include media-sm {
    width: 100%;
  }
}

.errorMessageContainer {
  position: absolute;
  top: -6px;
  right: 0;
  width: auto;
  height: 30px;
  line-height: 30px;
  color: red;
  font-size: 12px;
  @include media-sm {
    margin-left: 0;
  }
}
