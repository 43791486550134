.base {
  width: 16px;
  height: 16px;
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &[disabled] {
    opacity: 0.4;
  }
}
