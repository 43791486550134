@import '../../assets/styles/sizes';

.advantages {
  max-width: 1200px;
  margin: 48px auto;
  padding: 0 36px 48px;
  border-bottom: 1px solid var(--gray-300);

  @include media-sm {
    padding: 0 20px 20px;
    margin: 32px auto;
  }
}

.sliderWrapper {
  position: relative;
}

.slider {
  display: flex;

  @include media-sm {
    display: flex;
    flex-direction: column;
  }
}

.image {
  width: 100%;
  flex: 1;

  & img {
    width: 100%;
  }
}

.textWrapper {
  position: relative;
  flex: 1;
  margin-left: 80px;

  @include media-xl {
    margin-left: 40px;
  }

  @include media-md {
    margin-left: 24px;
  }

  @include media-sm {
    margin-left: 0;
  }
}

.title {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 42px;
  line-height: 52px;
  text-align: center;
  color: var(--black-500);
  margin: 24px 0 16px;

  @include media-md {
    margin: 0 0 8px;
  }

  @include media-sm {
    font-size: 32px;
    margin: 8px 0 8px;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--black-500);

  & .paragraph {
    @include media-md {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-md {
    font-size: 14px;
  }
}

.buttonsWrapper {
  @include media-sm {
    position: relative;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0 72px;
  }
}

.sliderButtonPrev,
.sliderButtonNext {
  position: absolute;
  width: 16px;
  height: 32px;
  background-size: cover;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(../../assets/images/icons/reviews-icon.svg);

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }

  @include media-sm {
    position: static;
    transform: none;
  }
}

.sliderButtonPrev {
  background-position: -40px 0;
  left: -32px;

  @include media-xl {
    left: -24px;
  }
}

.sliderButtonNext {
  background-position: -8px 0;
  right: -32px;

  @include media-xl {
    right: -24px;
  }
}
