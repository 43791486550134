.wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.HelpItemContent {
  padding-bottom: 70px;
}

.subtitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.08em;
  margin-top: 8px;
}

.sectionName {
  margin-top: 40px;
  font-weight: 500;
}

.link {
  color: var(--gray-500);
  text-decoration: none;
  border-bottom: 1px solid;
  transition: 200ms;
  &:hover {
    color: var(--black-500);
    border-color: var(--black-500);
    transition: 0ms;
  }
}
