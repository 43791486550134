.card {
  display: flex;
  cursor: pointer;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  &:focus-visible {
    outline: 3px solid red;
  }

  .contentBox {
    & .title {
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.08em;
      margin: 0;
    }
    & .content {
      display: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.08em;
      color: var(--gray-500);
    }
  }
}

.arrow {
  margin-right: 16px;
  & svg {
    transition: 200ms;
  }
}

.active {
  & .arrow {
    & svg {
      transform: rotate(180deg);
      transition: 200ms;
    }
  }
  & .contentBox {
    & .content {
      display: block;
    }
  }
}
