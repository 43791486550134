@import '../../../../../../assets/styles/sizes.scss';

.orderView {
  :global {
    [type='checkbox']:not(:checked) + label:before {
      border: 3px solid var(--gray-300);
    }

    [type='checkbox']:checked + label:before {
      border: 3px solid var(--black-500);
    }

    [type='checkbox']:not(:checked) + label:after,
    [type='checkbox']:checked + label:after {
      content: '';
      position: absolute;
      top: -11px;
      left: 3px;
      height: 20px;
      width: 20px;
      background-image: url(../../../../../../assets/images/logos/logo-mini.svg);
      transition: all 0.2s;
      @media (max-width: 1280px) {
        top: 3px;
        left: 3px;
      }
    }

    [type='checkbox']:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0) rotate(45deg);
    }
  }
}

.header {
  margin-bottom: 24px;

  @include media-sm {
    margin-bottom: 16px;
  }
}

.back {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);
  text-decoration: none;
  transition: color 0.2s;
  margin-bottom: 16px;

  &:hover {
    color: var(--black-500);
  }
}

.title {
  font-family: var(--secondary-font);
  font-size: 25px;
  margin-left: 30%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-xl {
    width: 100%;
    margin-left: 0;
  }

  @include media-sm {
    text-align: center;
  }
}

.subTitle {
  font-family: var(--secondary-font);
  font-size: 18px;
  letter-spacing: 6.66667px;
  text-transform: uppercase;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--gray-500);
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    letter-spacing: 4px;
    font-size: 18px;
  }
}
