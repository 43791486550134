@import '../../../../../../assets/styles/sizes';

.wrapper {
  display: flex;
  justify-content: center;
}

.radio {
  display: none;
}

.formRadio {
  position: relative;
  display: block;
  cursor: pointer;
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    z-index: 999;
    top: 5px;
    left: -42px;
    border: 3px solid var(--gray-300);
  }
}

.radio[type='radio']:checked ~ .formRadio::after {
  border-color: var(--black-500);
  background-image: url(./images/radio.svg);
  background-position: center;
}

.box,
.boxSelect {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.box {
  width: 352px;
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  padding: 8px 16px;
  & button {
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: var(--gray-500);
    font-size: 14px;
    font-weight: 500;
  }
  @include media-md {
    width: 100%;
  }
}

.edit {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
}

.boxSelect {
  width: 75%;

  & .card {
    width: 100%;
    margin-bottom: 10px;
    padding: 15px 30px 15px 72px;
    border: 1px solid transparent;
    transition: 200ms;
    cursor: pointer;

    &:hover {
      background: var(--gray-200);
      border: 1px solid var(--gray-300);
      transition: 0ms;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}
