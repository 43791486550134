@import '../../assets/styles/sizes';

.row {
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include media-sm {
    align-items: flex-start;
  }
}

.label {
  width: 100%;
  position: relative;
  text-align: left;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.input {
  width: 100%;
}
