.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 20px;
  &:focus-visible {
    outline: 2px solid var(--blue-200);
  }
}

.label {
  text-align: left;
  font-family: var(--secondary-font);
  font-size: 14px;
  letter-spacing: 1.45833px;
  font-weight: bold;
  color: var(--black-500);
  cursor: pointer;
  user-select: none;
}

.checkbox[type='checkbox']:not(:checked),
.checkbox[type='checkbox']:checked {
  position: absolute;
  left: 0;
  opacity: 0;
}

.checkbox[type='checkbox']:not(:checked) + label,
.checkbox[type='checkbox']:checked + label {
  position: relative;
  padding-left: 32px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox[type='checkbox']:not(:checked) + label:before,
.checkbox[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid var(--gray-300);
}

.checkbox[type='checkbox']:not(:checked) + label:after,
.checkbox[type='checkbox']:checked + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-image: url(../../assets/images/logos/logo-mini.svg);
  background-size: contain;
}

.checkbox[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
}

.checkbox[type='checkbox']:checked + label:after {
  opacity: 1;
}

.checkbox[type='checkbox']:focus-visible + label {
  outline: 2px solid var(--blue-200);
}
