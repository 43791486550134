.auth0-lock-blur-support {
  display: none;
}

body .auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: 65%;
  height: auto;
}

body .auth0-lock.auth0-lock .auth0-lock-widget {
  width: 410px;
}

body .auth0-lock.auth0-lock .auth0-lock-social-button.auth0-lock-social-big-button {
  max-width: 240px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  body .auth0-lock.auth0-lock .auth0-lock-header-logo {
    width: 50%;
  }
  .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current span {
    font-size: 16px !important;
  }
  body .auth0-lock.auth0-lock .auth0-lock-tabs li a {
    font-size: 16px;
  }
  body .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 100%;
    overflow-y: hidden;
  }

  .auth0-lock.auth0-lock .auth0-lock-cred-pane-internal-wrapper {
    height: 100% !important;
  }
}

body .auth0-lock.auth0-lock .auth0-lock-tabs li {
  font-size: 20px;
  font-family: 'Museo', sans-serif;
}

body .auth0-lock.auth0-lock .auth0-lock-tabs li.auth0-lock-tabs-current span {
  color: var(--black-500);
}
