.textInput {
  margin-bottom: 24px;
  justify-content: space-between;

  @media (max-width: 767px) {
    margin-bottom: 16px;
    flex-direction: column;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.marginBottom {
  margin-bottom: 56px;
}
