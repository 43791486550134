@import 'src/assets/styles/sizes';

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @include media-xl {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-md {
    grid-template-columns: repeat(1, 1fr);
  }
}

.addNewButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.addNewButton {
  @include media-md {
    width: 100%;
  }
}
