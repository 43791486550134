@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  --size: 32px;
  width: var(--size);
  height: var(--size);
  background-image: url(./images/LoadingWhite.svg);
  background-size: cover;
  animation: rotation 2s infinite linear;
  position: absolute;
  left: calc(50% - (var(--size) / 2));
  top: calc(50% - (var(--size) / 2));
  transform: translate(-50%, -50%);
  display: inline-block;
}
.small {
  --size: 16px;
}

.isBlack {
  background-image: url(./images/Loading.svg);
}
