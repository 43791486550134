@import '../../../../assets/styles/sizes';
@import '../../../../assets/styles/typography';

.loadingWrapper {
  position: relative;
  height: 472px;
  margin-bottom: 48px;

  @include media-sm {
    margin-bottom: 16px;
  }
}

.section {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 48px;
  scroll-margin: 80px;

  @include media-sm {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
}

.review {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray-100);
  border-radius: 12px;
  padding: 48px 24px;

  @include media-xl {
    margin-left: 32px;
    margin-right: 32px;
    flex-direction: column;
    justify-content: center;
  }

  @include media-sm {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    order: 2;
  }
}

.rating {
  display: flex;

  @include media-xl {
    margin-bottom: 24px;
  }
}

.image,
.star,
.sliderButtonPrev,
.sliderButtonNext,
.verified,
.infoIcon {
  background-image: url(../../../../assets/images/icons/reviews-icon.svg);
  background-size: cover;
}

.image {
  width: 100px;
  height: 100px;
  box-shadow: 0 2px 16px rgb(108 108 108 / 12%);
  background-color: var(--white);
  background-position: center;
  background-position: -500px 0;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 24px;

  @include media-sm {
    width: 65px;
    height: 65px;
    margin-right: 16px;
    background-position: -326px 0;
  }
}

.ratingBox {
  display: flex;
}

.ratingNumber {
  font-family: var(--secondary-font);
  font-weight: 300;
  font-size: 64px;
  line-height: 77px;
  color: var(--black-500);
}

.ratingText {
  @include landing--h3;
  font-weight: 600;
  color: var(--black-300);
  margin-left: 16px;

  @include media-sm {
    font-size: 16px;
    line-height: 22px;
  }
}

.ratingBox {
  display: flex;
}

.ratingLink {
  max-width: 160px;
}

.starWrapper {
  display: flex;
  margin-bottom: 8px;
}

.star {
  height: 32px;
  width: 32px;
  background-position: -224px 0;
}

.link {
  display: block;
  text-align: center;
  color: var(--black-500);
  font-weight: bold;
  &:hover {
    opacity: 0.8;
  }
}

.itemWrapper {
  position: relative;
  max-width: 650px;

  &::after {
    content: '“';
    font-family: var(--secondary-font);
    position: absolute;
    top: -12px;
    left: -32px;
    font-size: 64px;
    line-height: 77px;
    font-weight: 300;

    @include media-sm {
      left: -8px;
    }
  }

  @include media-sm {
    max-width: 100%;
  }
}

.item {
  @include media-xl {
    max-width: 600px;
    margin: 0 auto;
  }

  @include media-sm {
    max-width: 90%;
  }
}

.sliderButtonPrev,
.sliderButtonNext {
  position: absolute;
  width: 20px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 9;

  &:hover {
    opacity: 0.6;
  }
}

.sliderButtonPrev {
  background-position: -50px 0;
  left: -64px;

  @include media-xl {
    left: -20px;
  }
}

.sliderButtonNext {
  background-position: -10px 0;
  right: 0;

  @include media-xl {
    right: -20px;
  }
}

.itemText {
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: var(--black-500);
  max-width: 600px;

  @include media-sm {
    padding: 0 16px;
    font-size: 16px;
    line-height: 22px;
  }
}

.itemVerified {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.verified {
  background-position: -96px 0;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 48px;
  border-bottom: 1px solid var(--gray-300);

  @include media-md {
    flex-wrap: wrap;
  }

  @include media-sm {
    flex-direction: column;
    align-items: center;
    border-color: transparent;
  }
}

.infoItem {
  display: flex;
  align-items: center;
  max-width: 310px;
  @include media-md {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.infoIcon {
  min-width: 48px;
  height: 48px;
}

.shopping {
  background-position: -192px 0;
}

.independent {
  background-position: -96px 0;
}

.rating {
  background-position: -144px 0;
}

.infoBox {
  margin-left: 16px;
}

.infoTitle {
  @include landing--body;
  font-weight: 600;
  color: var(--black-500);
}

.infoText {
  @include landing--small;
  color: var(--gray-500);
  margin-top: 4px;
}
