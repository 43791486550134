@import '../../../../../../assets/styles/sizes';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  padding: 24px 32px;
  border: 1px solid var(--gray-300);
  background-color: var(--gray-100);

  @include media-sm {
    padding: 16px;
  }
}

.title {
  font-family: var(--secondary-font);
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 1.875px;
  color: var(--black-500);

  @media (max-width: 480px) {
    & br {
      display: none;
    }
  }
}
