@import '../../assets/styles/sizes';

:global {
  html {
    overflow-x: hidden;
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  body {
    --footer-height: 426px;
    min-width: var(--content-width);
  }
}

.wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.footer {
  height: var(--footer-height);
}

.container {
  justify-self: center;
  align-self: center;
  width: var(--content-width);
  margin: 0 auto;
  flex: 1 1 auto;

  @include media-sm {
    padding: 0 20px;
  }

  &_fullScreenWidth {
    width: 100%;

    @include media-sm {
      padding: 0;
    }
  }
}

@include media-sm {
  :global {
    body {
      overflow-y: auto;
    }

    .wrapper {
      height: auto;
    }

    .main {
      flex: 1 1 auto;
      overflow-y: visible;
    }
  }
}

@media screen {
  html {
    --content-width: 1200px;
  }
}

@include media-xl {
  html {
    --content-width: 1024px;
  }
}

@include media-lg {
  html {
    --content-width: 970px;
  }
}

@include media-md {
  html {
    --content-width: 750px;
  }
}

@include media-sm {
  html {
    --content-width: 100%;
  }
}
