@import '../../../../../../../assets/styles/sizes';

.wrapper {
  padding: 24px 0;
  text-align: center;
}

.infoWrapper {
  display: flex;
  align-items: center;
  margin: 24px 0;

  @include media-sm {
    flex-direction: column;
    margin: 16px 0;
  }
}

.box {
  flex: 1;
  &:first-child {
    margin-right: 16px;
  }
}

.button {
  margin: 0 auto;

  @include media-sm {
    &:first-child {
      margin: 0;
    }
  }
}

.image {
  background-image: url(../../../../../../../assets/images/icons/payment.svg);
  background-repeat: no-repeat;
  background-position: -194px 0;
  background-size: cover;
  width: 48px;
  height: 16px;
}

.info {
  display: block;
  max-width: 260px;
  margin: 0 auto;
  text-align: center;
  color: var(--black-500);
  &:hover {
    opacity: 0.9;
  }

  @include media-sm {
    padding-top: 16px;
    line-height: 24px;
  }
}

.icons {
  display: flex;
  justify-content: center;
}

.icon {
  margin-right: 16px;
  height: 24px;
  &:last-child {
    margin-right: 0;
  }
}

.buttonsWrapper {
  display: flex;

  & > button {
    margin: 0 16px 0 0;

    &:last-child {
      margin: 0;
    }
  }

  @include media-sm {
    flex-direction: column;
    gap: 8px;
  }
}
