@import '../../assets/styles/sizes';

.container {
  align-items: center;
  margin-bottom: 24px;
}

.title {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include media-sm {
    width: 100%;
    padding-bottom: 5px;
  }
}
