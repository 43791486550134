.container {
  position: fixed;
  width: 100%;
  display: flex;
  background-color: var(--black-500);
  color: white;
  justify-content: space-between;
  padding: 20px;
  z-index: 100;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
}

a {
  color: white;
}

p {
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.buttonWrapper {
  display: flex;
  align-items: flex-end;
  @media (max-width: 767px) {
    margin-top: 20px;
    justify-content: center;
  }
}

.button {
  position: relative;
  height: 48px;
  font-family: var(--primary-font);
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  padding: 17px 21px 16px 21px;
  border: solid 1px var(--black-500);
  background-color: white;
  color: var(--black-500);
  transition: 200ms;

  &:focus,
  &:hover {
    background-color: var(--gray-100);
    transition: 0ms;
  }
}
