.container {
  display: flex;
}

.title {
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.31px;
  line-height: 30px;
  color: var(--gray-500);
  flex: 0 0 25%;
  padding-right: 45px;
  @media (max-width: 767px) {
    padding-right: 0;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 600;
  }
}

.optionsContainer {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 75%;
}
