@import 'src/assets/styles/sizes';

.wrapper {
  max-width: 634px;
  margin: 0 auto;
}

.deliveryTimeText {
  color: var(--gray-500);
  margin-top: 16px;
}

.addNewButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  @include media-md {
    grid-template-columns: repeat(1, 1fr);
  }
}
