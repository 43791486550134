@import './sizes';

:root {
  --primary-font: 'Montserrat', 'Helvetica', sans-serif;
  --secondary-font: 'Museo', 'Segoe UI', sans-serif;
}

@mixin h2 {
  font-family: var(--secondary-font);
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: var(--black-500);
  margin: 24px 0 16px;
}

@mixin h3 {
  font-family: var(--secondary-font);
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: var(--black-500);
  margin: 16px 0 8px;
}

// Landing
@mixin landing--h1 {
  font-family: var(--secondary-font);
  font-size: 56px;
  line-height: 64px;
  font-weight: 300;

  @include media-sm {
    font-size: 40px;
    line-height: 46px;
  }
}

@mixin landing--h2 {
  font-family: var(--secondary-font);
  font-size: 42px;
  line-height: 52px;
  font-weight: 300;

  @include media-sm {
    font-size: 32px;
    line-height: 40px;
  }
}

@mixin landing--h3 {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;

  @include media-sm {
    font-size: 18px;
    line-height: 24px;
  }
}

@mixin landing--body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  @include media-sm {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin landing--body-caps {
  font-family: var(--secondary-font);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08em;

  @include media-sm {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin landing--small {
  font-size: 14px;
  line-height: 22px;

  @include media-sm {
    font-size: 13px;
    line-height: 20px;
  }
}

@mixin landing--small-caps {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.08em;

  @include media-sm {
    font-size: 12px;
    line-height: 18px;
  }
}

@mixin landing--tiny-caps {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
}

@mixin p {
  margin-bottom: 16px;
}
