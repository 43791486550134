@import '../../assets/styles/sizes';

.card {
  border-top: 1px solid var(--gray-300);
  padding-top: 16px;
  padding-bottom: 32px;
  display: grid;
  grid-template-columns: 35% 1fr;

  &:last-child {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--gray-300);

    @include media-md {
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  @include media-md {
    padding-top: 8px;
    padding-bottom: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.flexStart {
  align-items: flex-start;
}

.image {
  display: flex;
  margin-right: 24px;

  @include media-md {
    margin-right: 0;
    width: 15%;
  }
}

.title {
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.12em;
  color: var(--gray-500);

  @include media-md {
    margin-left: 15%;
  }
}

.infoWrapper {
  display: flex;
  align-items: center;

  @include media-md {
    width: 100%;
  }
}

.info {
  font-family: var(--secondary-font);
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: var(--black-300);
}
