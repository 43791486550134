@import '../../assets/styles/sizes';

.base {
  padding: 8px 16px;
  font-family: var(--primary-font);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.31px;
  line-height: 30px;
  color: var(--gray-500);
  border: 1px solid var(--gray-300);
  background: var(--white);

  &[disabled],
  &[readonly] {
    color: var(--gray-500);
    background-color: var(--gray-100);

    &:focus {
      border: 1px solid var(--gray-300);
    }
  }

  &::placeholder {
    font-weight: 400;
    line-height: 30px;
    color: var(--gray-500);
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:focus {
    border-color: var(--blue-200);
    outline: 0;
  }

  @include media-sm {
    line-height: 44px;
    padding: 0 8px;
    font-size: 14px;
    color: var(--black-500);
  }
}

.error {
  border: 1px solid red;
}
