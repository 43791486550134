@import 'assets/styles/typography';
@import 'assets/styles/colors';

:root {
  --header-height: 64px;
  --profile-height: calc(100vh - 68px);
}

*:focus-visible {
  outline: 2px solid var(--blue-200);
}

*:active {
  outline: none;
}

body {
  font-family: var(--primary-font);
  color: var(--black-500);
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}

.isModalOpened {
  overflow: hidden;
}

*,
::after,
::before {
  box-sizing: border-box;
}

strong {
  font-weight: 600 !important;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

p {
  @include p;
}
