@import '../../assets/styles/sizes';

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--black-500);
}

.text {
  text-align: center;
  font-size: 15px;
  margin-bottom: 8px;
}

.starList {
  margin-right: 4px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.star {
  stroke: var(--gray-400);
  fill: var(--gray-100);
  width: 28px;
  height: 28px;
  padding: 2px;
  transition: fill 0.15s ease, stroke 0.15s ease;
}

.star:hover,
.star:hover ~ .star {
  stroke: var(--yellow-300);
  fill: var(--yellow-300);
}
