.content {
  padding-bottom: 70px;

  p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    color: var(--black-500);
  }

  ul {
    li {
      line-height: 30px;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    color: var(--black-500);
    font-weight: 500;
  }

  table {
    padding: 50px 0;

    & td {
      border: 1px solid;
      padding: 10px;
    }
  }
}
