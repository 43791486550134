@import '../../assets/styles/sizes';

.wrapper {
  display: inline-flex;
  align-items: center;
  padding: 8px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  cursor: pointer;

  @include media-sm {
    flex-direction: column;
    border-radius: 2px;
  }
}

.box {
  display: flex;
  align-items: center;
}

.image {
  width: 20px;
  height: 20px;
  background-image: url(../../assets/images/icons/reviews-icon.svg);
  background-position: -100px 0;
  margin-right: 4px;
  background-size: cover;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: var(--black-500);
  text-transform: uppercase;
  margin-right: 16px;

  @include media-sm {
    margin-right: 0;
    font-size: 13px;
  }
}

.starList {
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.star {
  width: 18px;
  height: 18px;
  background-image: url(../../assets/images/icons/reviews-icon.svg);
  background-position: -126px 0;
  background-size: cover;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
  }
}

.rating {
  padding: 0 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--black-500);
  background-color: var(--yellow-300);
  border-radius: 32px;
}
